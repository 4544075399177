import React from 'react'
import { rhythm } from '@utils/typography'
import { Flex, Box } from 'rebass/styled-components'
import { Button } from '@elements/buttons'
import { useLockBodyScroll } from 'react-use'
import TransitionContext from '@context/transitioncontext'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

const FourOhFour = props => {
  const { location } = props
  useLockBodyScroll()
  const state = useTransitionState()
  const transition = { duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] }
  return (
    <TransitionContext location={location}>
      <AnimatePresence exitBeforeEnter>
        {['entering', 'entered'].includes(state.transitionStatus) && (
          <SidecardFlex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100vh"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={transition}
          >
            <Box
              style={{ margin: '0 auto', color: 'white', textAlign: 'center' }}
            >
              <h1>Uh oh, its a chunky.</h1>
              <p>I screwed up, and I&apos;m embarrassed.</p>
            </Box>
            <Box width="200px">
              <Button
                isLink
                linkTo="/"
                buttonText="GO HOME"
                padding={`0 ${rhythm(1)} `}
                height={`${rhythm(1.616)}`}
              />
            </Box>
          </SidecardFlex>
        )}
      </AnimatePresence>{' '}
    </TransitionContext>
  )
}

export default FourOhFour

const SidecardFlex = styled(motion.custom(Flex))``
